import React, { ChangeEvent, useMemo, useState } from "react";
import { Alert, Button, Card, Input, Space, Spin, Typography } from "antd";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ColGroupDef, FirstDataRenderedEvent, GridOptions, ValueGetterParams } from "ag-grid-community";
import { CORE_BASE_URL } from "../../../api/core/common.js";

const { TextArea } = Input;
const { Text } = Typography;

enum UPCStatus {
  Okay,
  WrongLength,
  NotANumber,
  Invalid
}

const UPCStatusMessage = {
  [UPCStatus.Okay]: 'UPC is valid',
  [UPCStatus.WrongLength]: 'UPC is not 12 characters in length',
  [UPCStatus.NotANumber]: 'UPC is not a number',
  [UPCStatus.Invalid]: 'UPC check-digit validation failed'
};

interface ProductVariant {
  art: string,
  blank: string,
  item: string,
  status: string,
  upc: string,
  weight: number
}

interface UPCValidation {
  value: string,
  status: UPCStatus
}

// regex to find all characters to be treated as separators
const regexSpace = /[\s,;]+/g;

export const ProductVariantPage = () => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [complete, setComplete] = useState(false);
  const [rowData, setRowData] = useState(Array<ProductVariant>);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const uniqueValues = useMemo(() => {
    const spaceFixedInput = input.replace(regexSpace, ' ');
    const values = spaceFixedInput.split(' ');
    const uniqueValues = values.reduce((accumulator: string[], current) => {
      if (
        !accumulator.some(
          (item) => item === current
        )
      ) {
        accumulator.push(current);
      }
      return accumulator;
    }, []).filter((id) => id.length > 0);
    return uniqueValues;
  }, [input]);

  const validateUPC = (upc: string) => {
    if (upc.length !== 12) {
      return UPCStatus.WrongLength;
    }

    const checkDigit = parseInt(upc[11], 10);
    const aDigits = parseInt(upc[0], 10) + parseInt(upc[2], 10) + parseInt(upc[4], 10) + parseInt(upc[6], 10) + parseInt(upc[8], 10)
      + parseInt(upc[10], 10);
    const bDigits = parseInt(upc[1], 10) + parseInt(upc[3], 10) + parseInt(upc[5], 10) + parseInt(upc[7], 10) + parseInt(upc[9], 10);

    if (Number.isNaN(aDigits) || Number.isNaN(bDigits) || Number.isNaN(checkDigit)) {
      return UPCStatus.NotANumber;
    }

    const total = aDigits * 3 + bDigits;
    let check = total % 10;
    if (check !== 0) {
      check = 10 - check;
    }
    if (check !== checkDigit) {
      return UPCStatus.Invalid;
    }

    return UPCStatus.Okay;
  };

  const validationValueGetter = (params: ValueGetterParams<UPCValidation>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const value = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    return UPCStatusMessage[value as UPCStatus];
  };

  const errorRowData: UPCValidation[] = useMemo(() => {
    return uniqueValues.map((uv) => ({
      status: validateUPC(uv),
      value: uv
    })).filter((p) => p.status !== UPCStatus.Okay);
  }, [uniqueValues]);

  const isDisabled = useMemo(() => uniqueValues.length === 0 || errorRowData.length !== 0, [uniqueValues, errorRowData]);

  const onFinish = async () => {
    setLoading(true);
    setRowData([]);
    setError('');
    setComplete(false);

    try {
      const response = await fetch(`${CORE_BASE_URL}/d365/product_variants`, {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ item_bar_code: uniqueValues })
      });

      if (!response.ok) {
        setError(response.statusText);
      } else {
        setRowData(await response.json() as ProductVariant[]);
      }
    } catch(e: unknown) { // catch is forced to type of unknown in modern TypeScript
      // handle any unexpected errors
      if (typeof e === "string") {
        setError(e);
      } else if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setLoading(false);
      setComplete(true);
    }
  };

  const defaultColDef = useMemo<ColDef>(() => ({
    sortable: true,
    enableCellChangeFlash: true,
    width: 120
  }), []);

  const columnDefs: Array<ColDef<ProductVariant> | ColGroupDef<ProductVariant>> = 
  useMemo(() => [
    {
      field: 'upc',
      headerName: 'UPC',
      sort: 'asc'
    },
    {
      field: 'item',
      headerName: 'Item'
    },
    {
      field: 'status',
      headerName: 'Status'
    },
    {
      field: 'weight',
      headerName: 'Weight'
    },
    {
      field: 'blank',
      headerName: 'Blank'
    },
    {
      field: 'color',
      headerName: 'Color'
    },
    {
      field: 'art',
      headerName: 'Art'
    }
  ], []);

  const errorColumnDefs: Array<ColDef<UPCValidation> | ColGroupDef<UPCValidation>> = useMemo(() => [
    {
      field: 'value',
      headerName: 'UPC',
      sort: 'asc'
    },
    {
      field: 'status',
      headerName: 'Error',
      valueGetter: validationValueGetter
    }
  ], []);

  const gridOptions: GridOptions<ProductVariant> = useMemo(() => ({
    rowData,
    columnDefs,
    defaultColDef,
    animateRows: true,
    domLayout: 'autoHeight',
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [rowData, columnDefs, defaultColDef]);

  const errorGridOptions: GridOptions<UPCValidation> = useMemo(() => ({
    rowData: errorRowData,
    columnDefs: errorColumnDefs,
    defaultColDef,
    animateRows: true,
    domLayout: 'autoHeight',
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [errorRowData, errorColumnDefs, defaultColDef]);

  return (
    <>
      <div style={{ margin: 60 }}>
        <Spin tip="Loading UPCs from D365" spinning={loading}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            { error && <Alert type="error" showIcon message={error} description=" " /> }
            <Card title="Input UPCs">
              <ul>
                <li>Type or paste UPCs you wish to find in D365.</li>
                <li>UPCs may be either comma separated or one-per-line.</li>
                <li>Any invalid UPCs must be fixed before submission.</li>
              </ul>
              <TextArea value={input} onChange={onChange} rows={6} />
              <Text type="secondary">{uniqueValues.length} Unique UPC{uniqueValues.length === 1 ? '' : 's'}</Text>
            </Card>
            {
              errorRowData.length > 0 &&
              (
                <Card title="Invalid UPCs">
                  <AgGridReact
                    className="ag-theme-madengine"
                    gridOptions={errorGridOptions}
                    rowData={errorRowData} // bind rowData directly here to update grid on data update
                  />
                  <Text type="secondary">{errorRowData.length} Error{errorRowData.length === 1 ? '' : 's'}</Text>
                </Card>
              )
            }
            <Button type="primary" disabled={isDisabled} onClick={onFinish}>Submit</Button>
            {
              (complete && rowData.length !== uniqueValues.length) &&
                <Alert type="warning" showIcon message="Input and Result UPC counts do not match." description=" " />
            }
            {
              (complete || rowData.length > 0) &&
              (
                <Card title="Results">
                  <AgGridReact
                    className="ag-theme-madengine"
                    gridOptions={gridOptions}
                    rowData={rowData} // bind rowData directly here to update grid on data update
                  />
                  <Text type="secondary">{rowData.length} Result{rowData.length === 1 ? '' : 's'}</Text>
                </Card>
              )
            }
          </Space>
        </Spin>
      </div>
    </>
  );
};
