import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

const { Paragraph } = Typography;

interface IdProps {
  id: string;
  link?: string;
  onClick?: React.MouseEventHandler<any>;
}

export const Id = ({ id, link, onClick }: IdProps) => {
  const children =
    onClick || link ? (
      <Link to={link ?? ""} onClick={onClick}>
        {id}
      </Link>
    ) : (
      id
    );

  return (
    <Paragraph
      copyable={{ text: id }}
      style={{
        cursor: onClick ? "pointer" : "default",
        marginBottom: 0,
      }}
    >
      {children}
    </Paragraph>
  );
};
