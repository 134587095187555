import React, { ChangeEvent, useCallback, useMemo, useState, useRef } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Button, Form, Input, Select, Spin, Typography, notification } from "antd";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

const { TextArea } = Input;
const { Text } = Typography;

// regex to find all characters to be treated as separators
const regexSpace = /[\s,;]+/g;

export const CustomsReportPage = () => {
  const [input, setInput] = useState('');
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [hasDownloadFailed, setDownloadFailed] = useState(false);
  
  // Reference to our fallback form
  const fallbackFormRef = useRef<HTMLFormElement>(null);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const uniqueValues = useMemo(() => {
    const spaceFixedInput = input.replace(regexSpace, ' ');
    const values = spaceFixedInput.split(' ');
    const uniqueValues = values.reduce((accumulator: string[], current) => {
      if (!accumulator.some((item) => item === current)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []).filter((id) => id.length > 0);
    return uniqueValues;
  }, [input]);

  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  const baseUrl = useMemo(() => `${CORE_BASE_URL}/line_items/report/customs`, []);

  const fileName = useMemo(
    () => `customs_${DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ')}.csv`,
    [timezone]
  );

  const download = useCallback(() => {
    setDownloadDisabled(true);
    setDownloadLoading(true);
    
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tracking_codes: uniqueValues
      })
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        setDownloadFailed(true);
        notification.open({
          key: "error",
          message: "Download Error",
          description: "Primary download failed. Click 'Try Alternative Download' to use the backup method.",
          duration: 0,
        });
      });
  }, [baseUrl, fileName, uniqueValues]);

  const onFallbackDownload = useCallback(() => {
    if (fallbackFormRef.current) {
      setDownloadFailed(false);
      setDownloadLoading(true);
      fallbackFormRef.current.submit();
      setTimeout(() => {
        setDownloadDisabled(false);
        setDownloadLoading(false);
      }, 5000);
    }
  }, []);

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Customs Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={setTimezone}
          />
        </Form.Item>
        <Form.Item
          label="Identifiers"
          extra="Generate a report for all included tracking numbers and order product ids. Identifiers may be line or comma separated."
          style={{ marginBottom: 10 }}
        >
          <TextArea value={input} onChange={onChange} rows={6} />
          <Text type="secondary">{uniqueValues.length} Unique Identifier{uniqueValues.length === 1 ? '' : 's'}</Text>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={download}>Download</Button>
        </Form.Item>
        {isDownloadLoading && (
          <Spin tip="Preparing Report..." size="large">
            <div className="content" />
          </Spin>
        )}
        {hasDownloadFailed && (
          <Button type="link" onClick={onFallbackDownload}>
            Try Alternative Download
          </Button>
        )}
      </Form>

      {/* Hidden form for fallback downloads */}
      <form 
        ref={fallbackFormRef}
        method="post" 
        action={baseUrl}
        encType="multipart/form-data"
        target="_blank"
        style={{ display: 'none' }}
      >
        {uniqueValues.map((value, index) => (
          <input
            key={index}
            type="hidden"
            name="tracking_codes[]"
            value={value}
          />
        ))}
      </form>
    </ErrorBoundary>
  );
};
